import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback, Children, useState } from "react";

//Import Icons
import FeatherIcon from "feather-icons-react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

//Import images
import giftBox from "../../assets/images/giftbox.png";

//i18n
import { withTranslation } from "react-i18next";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, useLocation, useNavigate } from "react-router-dom";

import withRouter from "../../../src/components/Common/withRouter";
import { title } from "process";
import { menu } from "../../constants/sideMenu";
import { ROUTER } from "src/constants/routes";
import { hasCustomerRoleHandler, hasDealerRoleHandler } from "src/helpers/common_helpers";

const SidebarContent = (props: any) => {
  const ref: any = useRef();
  const role: any = localStorage.getItem("role") || '';

  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items: any) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
      if (item.classList.contains("mm-active")) {
        item.classList.remove("mm-active");
      }
    }
  };

  const activeParent = (item: any) => {
    setTimeout(() => {
      item.classList.add("mm-active");
    }, 0);
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      let itemsActivePaths = items[i].dataset?.path ? JSON.parse(items[i].dataset?.path) : [];
      let itemActivePath = itemsActivePaths.some((path: string) => {
        if (pathName.includes("/edit") || pathName.includes("/view")) {
          const paths = pathName.split("/");
          paths.pop();
          const path_name = paths.join("/");
          return path_name === path;
        }
        return path === pathName;
      });
      if (itemActivePath) {
        matchingMenuItem = items[i];
        break;
      }

      // if (pathName === items[i].pathname) {
      //   matchingMenuItem = items[i];
      //   break;
      // }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    if (path?.pathname) {
      if (menu.some(each => each.path === path.pathname)) {
        const ul: any = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");

        let activeMenu = null;

        for (let i = 0; i < items.length; ++i) {
          if (items?.[i].dataset?.parentpath === path?.pathname) {
            activeMenu = items?.[i];
            break;
          }
        }

        if (activeMenu) {
          let data = activeMenu.parentElement
          activeParent(data);
        }
      }
    }
  }, [path?.pathname]);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
  const [sidebarSize, setsidebarSize] = useState<any>("")
  setTimeout(() => {
    var bodyElement = document.body;
    var sidebarSize = bodyElement.getAttribute('data-sidebar-size')
    setsidebarSize(sidebarSize)
  }, 500)



  const navigate = useNavigate();
  const ShowAttendance = (title: any) => {
    if (title == "Show Attendance") {
      navigate(ROUTER.SHOW_ATTENDANCE)
    }
    if (title == "Received Payments") {
      navigate(ROUTER.RECEIVED_PAYMENT_LIST)
    }
    if (title == "Invoices") {
      navigate(ROUTER.INVOICE_GRID)
    }
    if (title == "Incentive") {
      navigate(ROUTER.INCENTIVE_SYSTEM)
    }
    if (title == "Dashboard") {
      navigate(ROUTER.DASHBOARD)
    }
  }
  return (
    <React.Fragment>
      <SimpleBar className="max-height" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {menu.map((eachTitle: any) => {
              return (
                <React.Fragment key={eachTitle.title}>
                  {eachTitle?.access?.some((accessRole: any) => role.includes('"' + accessRole + '"')) && eachTitle.isAllow ? (

                    <li onClick={() => ShowAttendance(eachTitle.title)}>
                      <Link to={eachTitle.path} className={eachTitle.children?.length > 0 ? "has-arrow" : ""} data-parentpath={eachTitle?.path} >

                        <FeatherIcon icon={eachTitle.icon} />

                        {/* <span>{props.t(eachTitle.title)}</span> */}
                        <span>
                          {props.t(((hasCustomerRoleHandler() || hasDealerRoleHandler()) && eachTitle.title == "Received Payments") ? "Paid Payment" : ((hasCustomerRoleHandler()) && eachTitle.title == "Job Sheets") ? "Service Request" : (((hasDealerRoleHandler()) && eachTitle.title == "Job Sheets")) ? "Service Request List" :(((hasCustomerRoleHandler()) && eachTitle.title == "All tickets")) ? "Enquiries" : eachTitle.title)}
                        </span>
                      </Link>
                      {eachTitle.children?.length > 0 ? (
                        <ul className="sub-menu" >
                          {eachTitle.children.map((eachChildren: any) => {
                            return (
                              <React.Fragment key={eachChildren.title}>
                                {eachChildren?.access?.some((accessRole: any) => role.includes('"' + accessRole + '"')) && eachChildren.isAllow ? (
                                  <li className="custom-side-menu">
                                    <Link to={eachChildren.path} data-path={JSON.stringify(eachChildren?.activePaths)}>
                                      {/* {props.t(eachChildren.title)} */}
                                      {props.t(((hasCustomerRoleHandler() || hasDealerRoleHandler()) && eachChildren.title == "Received Payments") ? "Paid Payment" : ((hasCustomerRoleHandler()) && eachChildren.title == "Job Sheets") ? "Service Request" : (((hasDealerRoleHandler()) && eachChildren.title == "Job Sheets")) ? "Service Request List" :(((hasCustomerRoleHandler()) && eachTitle.title == "All tickets")) ? "Enquiries" : eachChildren.title)}
                                    </Link>
                                  </li>
                                ) : null}
                              </React.Fragment>
                            );
                          })}
                        </ul>) : null}
                    </li>
                  ) : null}
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));
