import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Col, Container, Input, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter';
import { ROUTER } from 'src/constants/routes';
import "./styles.scss";
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter';
import {
  CurrencyFormatter,
  formatDateMEL,
  formatDateTimeFormServerView,
  formatDateTimeForView,
  hasAdminOrEmployeeRoleHandler,
  hasCustomerRoleHandler,
  hasDealerRoleHandler,
  hasDealerSubuserRoleHandler,
  hasFranchiseRoleHandler,
  hasFranchiseSubuserRoleHandler,
  hasManufacturerRoleHandler,
  onlyAdminRoleHandler,
} from 'src/helpers/common_helpers';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearApproveDocumentLink,
  clearFranchiseDocumentLink,
  clearJobsheet,
  downloadEstimateJobSheetLoading,
  downloadInvoiceJobSheetLoading,
  downloadJobSheetLoading,
  downloadServiceRequestLoading,
  emailExchangeInvoiceLoading,
  emailServiceHistoryLoading,
  employeeDropdownForJobLoading,
  estimatorByUserDetailsLoading,
  getApproveDocumentLinkLoading,
  getApproveDocumentLoading,
  getEstimateConcernSignatureLoading,
  getFranchiseUploadedDocumentLinkLoading,
  getFranchiseUploadedDocumentLoading,
  getInvoiceDetailsByJobIdLoading,
  getJobsheetById,
  getJobsheetEnquiryById,
  getJobsheetsStatus,
  getPendingReasonLoading,
  getTechnicianDropdownLoading,
  getTechnicianbyIdLoading,
  getVehiclebrandDropdownLoading,
  getcustomerdropdownListLoading,
  jobDealerdropdownListLoading,
  jobSheetClearMessage,
  partsDropdownForJobLoading,
  printExchangeInvoiceLoading,
  printServiceHistoryLoading,
  receivedPaymentForInvoiceJobSheetLoading,
  sendInvoiceMailJobSheetLoading,
  sendMailJobSheetLoading,
  sendSMSJobSheetLoading,
} from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import {
  EXCHANGE_INVOICE,
  isCreateFeedback,
  isShowCreateEnquiry,
  isShowCreateExchangePartOutbound,
  isShowCreatePartsOutbound,
  isShowEdit,
  isShowEditExchangePartOutbound,
  isShowEditPartsOutbound,
  isShowEmailEstimate,
  isShowEmailInvoice,
  isShowPrintEstimate,
  isShowPrintExchnageInvoice,
  isShowPrintInvoice,
  isShowPrintJobSheet,
  isShowPrintServiceRequest,
  OTHER,
  SERVICE_INVOICE,
} from 'src/helpers/jobsheet_validation';
import { useToast } from 'src/components/Common/ReactToaster';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import { Link } from 'react-router-dom';
import JobTooltipMEL from 'src/components/UI/JobTooltip';
import { Columns, LazyTableState } from 'src/models/components/dataTableWrapperModel';
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import SortIcon from 'src/components/Common/CustomFilterandSortIcon/SortIcon';
import FilterIcon from 'src/components/Common/CustomFilterandSortIcon/FilterIcon';
import HtmltoPlainText from 'src/components/atoms/HtmltoPlainText';
import { getProfile } from 'src/store/profile/actions';
import PdfImg from 'src/assets/images/PDF-file.png';
import ExcelImg from 'src/assets/images/Excel-file.png';
import FileImg from 'src/assets/images/files.png';
import ImageFile from 'src/assets/images/Image-file.png';
import VideoImg from 'src/assets/images/video-file.png';

const purchaseListConverson = (values: any[]) => {
  const modifeidValue = values?.map((eachValue) => {
    let data: any = {};

    data['id'] = eachValue?.id;
    data['JobId'] = eachValue?.jobNumber;
    data['dateOfEnquiry'] = eachValue?.dateOfEnquiry;
    data['enquirerName'] = eachValue?.enquirerName;
    data['isCustomer'] = eachValue?.isCustomer;
    data['attendedById'] = eachValue?.userName;
    return data;
  });
  return modifeidValue;
};

const ViewJobSheet = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobsheet, dealerList, customerList, vehicleBrandDropdownListPage, pendingReason, employeeList, partsList, technicianList, loading, courierList, invoiceDetails, jobsheetId, error, message, receivedPayment, concernImage, prepareByIdData, updatedByIdData, technicianByIdData, estimatorByIdData, jobSheetStatus, franchiseDocumet, franchiseDocumetLink, approveDocument, approveDocumentLink } = useSelector((state: RootReducerState) => state.jobsheetReducer);
  const { values, totalRecords, paginatorCount } = useSelector((state: RootReducerState) => state.jobsheetReducer.enquiryList);
  const modifiedValues: any[] = purchaseListConverson(values);
  const { showToast } = useToast();
  const isFranshieLogin = hasFranchiseRoleHandler();
  const isCustomerLogin = hasCustomerRoleHandler();
  const dt = useRef<DataTable<any>>(null);
  const { profile } = useSelector((state: RootReducerState) => state.profilesReducer);

  useEffect(() => {
    dispatch(getProfile());
  }, []);


  const editJobSheet = () => {
    navigate(`${ROUTER.JOB_SHEETS_EDIT}/${id}`);
  };
  useEffect(() => {
    if (jobsheet?.technicianGUId) {
      dispatch(getTechnicianbyIdLoading(jobsheet?.technicianGUId));
    }
  }, [jobsheet?.technicianGUId]);

  useEffect(() => {
    if (jobsheet?.estimatorGUId) {
      dispatch(estimatorByUserDetailsLoading(jobsheet?.estimatorGUId));
    }
  }, [jobsheet?.estimatorGUId]);

  const statusBodyTemplate = (value: boolean) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    );

  };

  useEffect(() => {
    if (approveDocumentLink != "" && approveDocumentLink != undefined && approveDocumentLink != null) {
      const newWindow = window.open(approveDocumentLink);
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        alert('Popup blocked! Please allow popups for this site to view the document.');
      } else {
        newWindow.focus();
      }
      dispatch(clearApproveDocumentLink());
    }
  }, [approveDocumentLink]);

  useEffect(() => {
    if (franchiseDocumetLink != "" && franchiseDocumetLink != undefined && franchiseDocumetLink != null) {
      const newWindow = window.open(franchiseDocumetLink);
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        alert('Popup blocked! Please allow popups for this site to view the document.');
      } else {
        newWindow.focus();
      }
      dispatch(clearFranchiseDocumentLink());
    }
  }, [franchiseDocumetLink]);

  const [visible, setVisible] = useState(false);
  const [serviceInvoiceId, setServiceInvoiceId] = useState("");
  const [exchangeServiceInvoiceId, setExchangeServiceInvoiceId] = useState("");


  useEffect(() => {
    if (message) {
      if (message) {
        showToast(message, { type: "success" });
        dispatch(jobSheetClearMessage());
      }

    }
    if (error) {
      setVisible(true);
    }
  }, [message, error]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(jobSheetClearMessage());
  };

  const dialogContainer = (error: any) => {
    return (<ValidationPopup error={error} visible={visible} onHide={hidePopup} />);
  };

  useEffect(() => {
    if (id) {
      dispatch(getJobsheetById(id));
      dispatch(jobDealerdropdownListLoading());
      dispatch(getcustomerdropdownListLoading());
      dispatch(getVehiclebrandDropdownLoading());
      dispatch(getPendingReasonLoading());
      dispatch(getTechnicianDropdownLoading());
      dispatch(employeeDropdownForJobLoading());

      // dispatch(courierDropdownForJobLoading())

      // if(jobsheet?.dealerId && !jobsheet?.customerId){
      //   dispatch(courierDropdownForJobLoading(jobsheet?.dealerId))
      // }else if(!jobsheet?.dealerId && jobsheet?.customerId){
      //   dispatch(courierDropdownForJobLoading(jobsheet?.customerId))
      // }else{
      //   dispatch(courierDropdownForJobLoading(jobsheet?.dealerId))
      // }

      dispatch(partsDropdownForJobLoading());
      dispatch(getInvoiceDetailsByJobIdLoading(id));
      if (!hasDealerSubuserRoleHandler() && !hasManufacturerRoleHandler()) {
        dispatch(getFranchiseUploadedDocumentLoading(id));
        dispatch(getApproveDocumentLoading(id));
      }
    }
    dispatch(getJobsheetsStatus(jobSheetStatus));
    return () => {
      dispatch(clearJobsheet());
    };
  }, [id]);

  const filterDetails: DataTableFilterMeta = {
    jobid: { value: Number(id), matchMode: "contains" },
    dateOfEnquiry: { value: "", matchMode: "contains" },
    StartDateOfEnquiry: { value: "", matchMode: "contains" },
    EndDateOfEnquiry: { value: "", matchMode: "contains" },
    enquirerName: { value: "", matchMode: "contains" },
    AttendedById: { value: "", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "creationTime",
    sortOrder: -1,
    filters: filterDetails,
  };


  const [lazyState, setLazyState] = useState(initialLazyState);

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <label htmlFor="">Start Date</label>
      <Input type="date" required value={options?.value?.startDate} max={options?.value?.endDate ? options?.value?.endDate : ""} onChange={(e: any) => {
        options;
        let modifiedDate: any = options.value || { startDate: "", endDate: "" };
        modifiedDate['startDate'] = e.target.value;
        options.filterCallback(modifiedDate, options.index);
      }} />
      <br />
      <label htmlFor="">End Date</label>
      <Input type="date" required value={options?.value?.endDate} min={options?.value?.startDate ? options?.value?.startDate : ""} onChange={(e: any) => {
        options;
        let modifiedDate: any = options.value || { startDate: "", endDate: "" };
        modifiedDate['endDate'] = e.target.value;
        options.filterCallback(modifiedDate, options.index);
      }} />
    </>);
  };

  const columns: Columns[] = [
    {
      field: "JobId",
      sortable: false,
      header: "Job Sheet",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isDefault: true,
      isStatic: true,
    },
    {
      field: "dateOfEnquiry",
      sortable: false,
      header: "Date Of Enquiry",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      body: (rowData) => formatDateMEL(rowData.dateOfEnquiry),
    },
    {
      field: "enquirerName",
      sortable: false,
      header: "Enquirer Name",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },

    {
      field: "isCustomer",
      sortable: false,
      header: "Is Customer",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => statusBodyTemplate(rowData.isCustomer),
      isDefault: true,
      isStatic: false,

    },
    {
      field: "attendedById",
      sortable: false,
      header: "Attented By",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
  ];
  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    event['filters']['StartDateOfEnquiry']['value'] = event?.filters?.dateOfEnquiry?.value?.startDate ? event?.filters?.dateOfEnquiry?.value?.startDate : '';
    event['filters']['EndDateOfEnquiry']['value'] = event?.filters?.dateOfEnquiry?.value?.endDate ? event?.filters?.dateOfEnquiry?.value?.endDate : '';

    setLazyState(event);
  };
  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };
    if (modifiedLazyState.filters && modifiedLazyState.filters.dateOfEnquiry) {
      modifiedLazyState.filters.dateOfEnquiry.value = "";
    }
    dispatch(getJobsheetEnquiryById(modifiedLazyState));
  };

  useEffect(() => {
    callbackFunction(lazyState);
  }, [lazyState]);
  const [dealerName, setDealerName] = useState<string>("");
  const [customerName, setCustomerName] = useState<string>("");
  const [vehicleBrandName, setVehicleBrandName] = useState<string>("");
  const [estimateTotal, setEstimateTotal] = useState<string>("");
  const [estimate, setEstimate] = useState<any>({});

  useEffect(() => {
    if (invoiceDetails?.length > 0) {
      invoiceDetails.forEach((eachInvoice: any, index: number) => {
        if (eachInvoice.invoiceTypeName == SERVICE_INVOICE) {
          setServiceInvoiceId(eachInvoice.id);
        }
        if (eachInvoice.invoiceTypeName == EXCHANGE_INVOICE) {
          setExchangeServiceInvoiceId(eachInvoice.id);
        }
        (!hasFranchiseSubuserRoleHandler() && !hasManufacturerRoleHandler()) && (dispatch(receivedPaymentForInvoiceJobSheetLoading(eachInvoice?.id, index)));

      });


    }
  }, [invoiceDetails]);

  // useEffect(() => {
  //   if (invoiceDetails?.length > 0) {
  //     invoiceDetails.forEach((eachInvoice: any, index: number) => {
  //       if (eachInvoice.invoiceTypeName == SERVICE_INVOICE) {
  //         setServiceInvoiceId(eachInvoice.id)
  //       }
  //       if (eachInvoice.invoiceTypeName == EXCHANGE_INVOICE) {
  //         setExchangeServiceInvoiceId(eachInvoice.id)
  //       }

  //       dispatch(receivedPaymentForInvoiceJobSheetLoading(eachInvoice?.id, index))
  //     })


  //   }
  // }, [invoiceDetails])


  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  useEffect(() => {
    const dealerName = jobsheet?.dealerId ? dealerList?.find((item: any) => item.id == jobsheet?.dealerId)?.name : "";
    setDealerName(dealerName);
    const customerName = jobsheet?.customerId ? customerList?.find((item: any) => item.id == jobsheet?.customerId)?.name : "";
    setCustomerName(customerName);
    const VehicleBrandNameName = jobsheet?.vehicleBrandId ? vehicleBrandDropdownListPage?.vehicleBrands?.find((item: any) => item.id == jobsheet?.vehicleBrandId)?.name : "";
    setVehicleBrandName(VehicleBrandNameName);
    let estimateTotal = jobsheet?.jobEstimateDto.filter((eachValue: any) => !eachValue.isDeleted)?.[0]?.totalEstimation;
    setEstimateTotal(estimateTotal >= 0 ? CurrencyFormatter({ value: estimateTotal }) : "");
    setEstimate(jobsheet?.jobEstimateDto.filter((eachValue: any) => !eachValue.isDeleted)?.[0]);
  }, [jobsheet]);

  const employeeDropList = employeeList?.length > 0 ? (employeeList.map((eachEmployee: any) => {
    let data: any = {};
    data['value'] = eachEmployee.userId;
    data['label'] = eachEmployee.name;
    data['id'] = eachEmployee.id;
    return data;
  })) : [];

  const technicianDropList = technicianList?.length > 0 ? (technicianList.map((eachEmployee: any) => {
    let data: any = {};
    data['value'] = eachEmployee.userId;
    data['label'] = eachEmployee.name;
    data['id'] = eachEmployee.id;
    return data;
  })) : [];

  const technicianEmployeeDropList = [...employeeDropList, ...technicianDropList];

  const courierDropList = courierList?.length > 0 ? (courierList.map((eachCourier: any) => {
    let data: any = {};
    data['value'] = eachCourier.id;
    data['label'] = eachCourier.courierMasterName;
    return data;
  })) : [];


  useEffect(() => {
    debugger;
    let isConcernSignature: boolean = jobsheet?.jobEstimateDto?.filter((data: any) => data.isDeleted == false)[0]?.approvalMode == "Portal";
    if (isConcernSignature) {
      let estimateId: number = jobsheet?.jobEstimateDto?.filter((data: any) => data.isDeleted == false)[0]?.id;
      dispatch(getEstimateConcernSignatureLoading(estimateId));
    }
  }, [jobsheet?.jobEstimateDto]);


  const partsDropList = partsList?.length > 0 ? (partsList.map((eachParts: any) => {
    let data: any = {};
    data['value'] = eachParts.id;
    data['label'] = eachParts.partNumber;
    return data;
  })) : [];
  const DealerTemplate = (name: any) => {
    return (
      <>
        {
          (isCustomerLogin || hasDealerRoleHandler() || hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) ? name : <Link to={`${ROUTER.DEALERS_VIEW}/${jobsheet?.dealerId}`}>{name}</Link>
        }
      </>

    );
  };

  const CustomerTemplate = (name: any) => {
    return (
      <>
        {
          (isFranshieLogin || isCustomerLogin || hasDealerRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) ? name : jobsheet?.customerId ?  <Link to={`${ROUTER.CUSTOMERS_VIEW}/${jobsheet?.customerId}`}>{name}</Link> : name
        }
      </>

    );
  };

  const AudioModelTemplate = (name: any) => {
    return (
      <>
        {
          (isFranshieLogin || isCustomerLogin || hasDealerRoleHandler() || hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) ? name : <Link to={`${ROUTER.AUDIO_MODELS_VIEW}/${jobsheet?.audioModelId}`}>{name}</Link>
        }
      </>
    );
  };

  const VehicleBrandTemplate = (name: any) => {
    return (
      <>
        {
          (isFranshieLogin || isCustomerLogin || hasDealerRoleHandler() || hasFranchiseRoleHandler() || hasFranchiseSubuserRoleHandler() || hasDealerSubuserRoleHandler() || hasManufacturerRoleHandler()) ? name : <Link to={`${ROUTER.VEHICLE_BRANDS_VIEW}/${jobsheet?.vehicleBrandId}`}>{name}</Link>
        }
      </>
    );
  };



  const navigatepreparedby = (name: string, id: any) => {
    return (
      <>
        {
          (onlyAdminRoleHandler() || profile?.extraProperties?.IsAdmin == true) ? <Link to={`${ROUTER.USERS_VIEW}/${id}`}>{name}</Link> : <>{name}</>
        }
      </>

    );
  };

  const showUpdateUser = (updateUserDetails: any) => {
    debugger;
    return (
      <>
        {
          (onlyAdminRoleHandler() || profile?.extraProperties?.IsAdmin == true) ? <Link to={`${ROUTER.USERS_VIEW}/${updateUserDetails?.melEmployeeId}`}>{updateUserDetails?.name}</Link> : <>
            {updateUserDetails?.name}
          </>
        }
      </>

    );
  };
  const navigateTechnician = (name: string, id: any) => {
    let url = technicianByIdData?.userType == "Franchise" ? ROUTER.FRANCHISEE_VIEW : ROUTER.USERS_VIEW;

    return (
      <>
        {
          hasAdminOrEmployeeRoleHandler() ? <Link to={`${url}/${id}`}>{name}</Link> : <>{name}</>
        }
      </>

    );
  };
  const navigateEstimator = (name: string, id: any) => {
    let url = estimatorByIdData?.userType == "Franchise" ? ROUTER.FRANCHISEE_VIEW : ROUTER.USERS_VIEW;
    return (
      <>
        {
          (onlyAdminRoleHandler() || profile?.extraProperties?.IsAdmin == true) ? <Link to={`${url}/${id}`}>{name}</Link> : <>{name}</>
        }
      </>

    );
  };

  const isImageMimeType = (mimeType: any) => {
    const imageMimeTypes = /^image\/(png|jpeg|jpg|gif|svg\+xml|bmp|webp|tiff|ico)$/;
    return imageMimeTypes.test(mimeType);
  };

  const openDocumet = (id: any, documentType: string) => {
    if (documentType == "franchise") {
      dispatch(getFranchiseUploadedDocumentLinkLoading(id));
    } else {
      dispatch(getApproveDocumentLinkLoading(id));
    }
  };
  const imageMimeTypes = ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/bmp", "image/webp", "image/avif", "image/tiff", "image/svg+xml"];
  const pdfMimeTypes = ["application/pdf"];
  const excelMimeTypes = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
  const videoMimeTypes = ["video/mp4", "video/quicktime", "video/x-msvideo", "video/x-ms-wmv", "video/mpeg", "video/x-flv", "video/webm", "video/ogg", "video/3gpp", "video/3gpp2"];

  const documentTemplate = (franchiseDocumet: any, documentType: any) => {
    return (
      <div className='Franchise_Documnets'>
        {
          franchiseDocumet?.length > 0 ? franchiseDocumet?.map((data: any, i: number) => {
            if (pdfMimeTypes.includes(data.mimeType)) {
              return (
                <JobTooltipMEL key={i} title={data.name} position='bottom'>
                  <div onClick={() => openDocumet(data.id, documentType)}>
                    <img src={PdfImg} alt="document" style={{ width: "70px", height: "100px" }} />
                  </div>
                </JobTooltipMEL>
              );
            } else if (excelMimeTypes.includes(data.mimeType)) {
              return (
                <JobTooltipMEL key={i} title={data.name} position='bottom'>
                  {/* <i key={i} onClick={() => openDocumet(data.id, documentType)} className="fas fa-file-csv"></i> */}
                  <div onClick={() => openDocumet(data.id, documentType)}>
                    <img src={ExcelImg} alt="document" style={{ width: "70px", height: "100px" }} />
                  </div>
                </JobTooltipMEL>
              );
            } else if (imageMimeTypes.includes(data.mimeType)) {
              return (
                <JobTooltipMEL key={i} title={data.name} position='bottom'>
                  {/* <i key={i} onClick={() => openDocumet(data.id, documentType)} className="fas fas fa-image"></i> */}
                  <div onClick={() => openDocumet(data.id, documentType)}>
                    <img src={ImageFile} alt="document" style={{ width: "70px", height: "100px" }} />
                  </div>
                </JobTooltipMEL>
              );
            } else if (videoMimeTypes.includes(data.mimeType)) {
              return (
                <JobTooltipMEL key={i} title={data.name} position='bottom'>
                  {/* <i key={i} onClick={() => openDocumet(data.id, documentType)} className="fas fas fa-image"></i> */}
                  <div onClick={() => openDocumet(data.id, documentType)}>
                    <img src={VideoImg} alt="document" style={{ width: "70px", height: "100px" }} />
                  </div>
                </JobTooltipMEL>
              );
            } else {
              return (
                <JobTooltipMEL key={i} title={data.name} position='bottom'>
                  {/* <i key={i} onClick={() => openDocumet(data.id, documentType)} className="fas fa-file-alt"></i> */}
                  <div onClick={() => openDocumet(data.id, documentType)}>
                    <img src={FileImg} alt="document" style={{ width: "70px", height: "100px" }} />
                  </div>
                </JobTooltipMEL>
              );
            }
          }) : <span style={{ fontSize: "14px" }}>No attachement here</span>
        }
      </div>
    );
  };

  // const convertMinutes = (minutes: any) => {
  //   console.log("minutes",minutes)
  //   if (minutes == 0) {
  //     return ("less than minutes")
  //   } else {
  //     let hours = Math.floor(minutes / 60);
  //     let remainingMinutes = minutes % 60;
  //     return (`${hours} hours and ${remainingMinutes} minutes`);
  //   }
  // }

  const convertMinutes = (minutes: any) => {
    let years = Math.floor(minutes / (60 * 24 * 365));
    minutes %= (60 * 24 * 365);
    let months = Math.floor(minutes / (60 * 24 * 30));
    minutes %= (60 * 24 * 30);
    let days = Math.floor(minutes / (60 * 24));
    minutes %= (60 * 24);
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    let output = [];
    if (years > 0) {
      output.push(`${years} year${years > 1 ? 's' : ''}`);
    }
    if (months > 0) {
      output.push(`${months} month${months > 1 ? 's' : ''}`);
    }
    if (days > 0) {
      output.push(`${days} day${days > 1 ? 's' : ''}`);
    }
    if (hours > 0) {
      output.push(`${hours} hour${hours > 1 ? 's' : ''}`);
    }
    if (remainingMinutes > 0) {
      output.push(`${remainingMinutes.toFixed(1)} minute${remainingMinutes > 1 ? 's' : ''}`);
    }
    if (output.length === 0) {
      return "less than a minute";
    } else {
      return (output.join(", "));
    }
  };


  const pendingComments = jobsheet?.pendingCommentsDto?.filter((item: any) => item.isDeleted !== true);
  const JobSheetView = {
    josheetDetails: [
      { name: "Number", value: jobsheet?.number ? jobsheet?.number : "To be generated" },
      { name: "Service Request Number", value: jobsheet?.serviceRequestNumber },
      { name: "Service Request Created On", value: jobsheet?.serviceRequestGeneratedDate ? formatDateTimeFormServerView(jobsheet?.serviceRequestGeneratedDate) : "" },
      { name: "Job Number Created At", value: jobsheet?.jobNumberCreatedAt ? formatDateTimeFormServerView(jobsheet?.jobNumberCreatedAt) : "" },
      { name: "Dealer", value: jobsheet?.dealerName ? DealerTemplate(jobsheet?.dealerName) : "" },
      { name: "Dealer Code", value: jobsheet?.dealerCode ? jobsheet?.dealerCode : "-" },
      { name: "Customer", value: jobsheet?.customerName ? CustomerTemplate(jobsheet?.customerName) : "" },
      { name: "Customer Mobile", value: jobsheet?.customerPhoneNumber ? jobsheet?.customerPhoneNumber : "" },
      { name: "Customer Email", value: jobsheet?.customerEmail ? jobsheet?.customerEmail : "" },
      { name: "Customer Address", value: jobsheet?.customerAddress ? jobsheet?.customerAddress : "" },
      { name: "Estimator", value: jobsheet?.estimatorName ? navigateEstimator(jobsheet.estimatorName, jobsheet?.estimatorId) : "" },
      { name: "Technician", value: jobsheet?.technicianName ? navigateTechnician(jobsheet?.technicianName, jobsheet?.technicianId) : "" },
      { name: "Systembrand Model", value: jobsheet?.audioModel ? AudioModelTemplate(jobsheet?.audioModel) : "" },
      { name: "Warranty Status", value: jobsheet?.warrantyStatus ? jobsheet?.warrantyStatus : "" },
      { name: "Vin Number", value: jobsheet?.vinNumber ? jobsheet?.vinNumber : "" },
      { name: "Vehicle Registration Number", value: jobsheet?.vehicleRegistrationNumber ? jobsheet?.vehicleRegistrationNumber : "" },
      { name: "Vehicle Brand", value: vehicleBrandName ? VehicleBrandTemplate(vehicleBrandName) : "" },
      { name: "Vehicle Model", value: jobsheet?.vehicleModel ? jobsheet?.vehicleModel : "" },
      { name: "New Audio Serial Number", value: jobsheet?.outgoingAudioSerialNumber ? jobsheet?.outgoingAudioSerialNumber : "" },
      { name: "Failed Audio Serial Number", value: jobsheet?.audioSerialNumber ? jobsheet?.audioSerialNumber : "" },
      { name: "Audio Part Number", value: jobsheet?.audioPartNumber ? jobsheet?.audioPartNumber : "" },
      { name: "Job Sheet Created Date", value: jobsheet?.creationTime ? formatDateTimeFormServerView(jobsheet?.creationTime) : "" },
      { name: "Service Request Received Date", value: jobsheet?.serviceRequestGeneratedDate ? formatDateTimeForView(jobsheet?.serviceRequestGeneratedDate, false) : "" },
      { name: "Request Date", value: jobsheet?.requestDate ? formatDateTimeForView(jobsheet?.requestDate, false) : "" },
      { name: "Vehicle Date Of Sale", value: jobsheet?.dateOfSale ? formatDateTimeForView(jobsheet?.dateOfSale, false) : "" },
      { name: "Failed Audio Manufactured Date", value: jobsheet?.manufacturedDate ? formatDateTimeForView(jobsheet?.manufacturedDate, false) : "" },
      { name: "Failed Date", value: jobsheet?.failedDate ? formatDateTimeForView(jobsheet?.failedDate, false) : "" },
      { name: "Last Repair Date", value: jobsheet?.lastRepairDate ? formatDateTimeForView(jobsheet?.lastRepairDate, false) : "" },
      { name: "Vehicle KM Covered", value: jobsheet?.kmsCovered ? jobsheet?.kmsCovered : "" },
      { name: "Customer Concern", value: jobsheet?.customerConcern ? <HtmltoPlainText htmlString={jobsheet?.customerConcern} /> : "" },
      { name: "Dealer Observation", value: jobsheet?.dealerObservation ? <HtmltoPlainText htmlString={jobsheet?.dealerObservation} /> : "" },
      { name: "Accessories List", value: jobsheet?.accessoriesList ? <HtmltoPlainText htmlString={jobsheet?.accessoriesList} /> : "" },
      { name: "Missing Accessories", value: jobsheet?.missingAccessories ? <HtmltoPlainText htmlString={jobsheet?.missingAccessories} /> : "" },
      { name: "Additional Remarks", value: jobsheet?.additionalRemarks ? <HtmltoPlainText htmlString={jobsheet?.additionalRemarks} /> : "" },
      { name: "Status", value: jobsheet?.status ? <HtmltoPlainText htmlString={jobsheet?.status} /> : "" },
      { name: "In Pending", value: statusBodyTemplate(jobsheet?.inPending ? true : false) },
      { name: "Is Exchange", value: statusBodyTemplate(jobsheet?.isExchange ? true : false) },
      { name: "Is Remote Service", value: statusBodyTemplate(jobsheet?.isRemoteService ? true : false) },
      { name: "Is Faulty System Available", value: statusBodyTemplate(jobsheet?.isFaultySystemAvailable ? true : false) },
      { name: "Prepared By", value: prepareByIdData?.melEmployeeId ? navigatepreparedby(jobsheet?.preparedBy?.name, prepareByIdData?.melEmployeeId) : jobsheet?.preparedBy?.name },
      { name: "Service Charge", value: jobsheet?.serviceCharge >= 0 ? CurrencyFormatter({ value: jobsheet?.serviceCharge }) : "" },
      { name: "Total Estimate", value: estimateTotal },
      { name: "Mel Observations", value: jobsheet?.melObservations ? <HtmltoPlainText htmlString={jobsheet?.melObservations} /> : "" },
      { name: "Complete Service Information", value: jobsheet?.completeServiceInformation ? <HtmltoPlainText htmlString={jobsheet?.completeServiceInformation} /> : "" },
      { name: "Packing Complete", value: statusBodyTemplate(jobsheet?.packingComplete ? true : false) },
      { name: "Cleaning Complete", value: statusBodyTemplate(jobsheet?.cleaningComplete ? true : false) },
      { name: "Packaged At", value: jobsheet?.packagedAt ? formatDateTimeForView(jobsheet?.packagedAt) : "" },
      ...((hasCustomerRoleHandler() || hasDealerRoleHandler() || hasAdminOrEmployeeRoleHandler()) ? [{ name: "Otp", value: jobsheet?.otp ? jobsheet?.otp : "-" }] : []),
      { name: "Franchise Notes", value: jobsheet?.franchiseNotes ? jobsheet?.franchiseNotes : "-" },
      { name: "Appointment Time", value: jobsheet?.appointmentTime ? formatDateTimeForView(jobsheet?.appointmentTime) : "" },
      { name: "Mel Tat", value: jobsheet?.melTat ? convertMinutes(((jobsheet?.melTat ? jobsheet?.melTat : 0) + 1) / 60) : "" },
      { name: "Other Tat", value: convertMinutes(((jobsheet?.otherTat ? jobsheet?.otherTat : 0) + 1) / 60) }
    ],
    estimate: {
      general: {
        generalDetais: [
          { name: "Estimator Comments", value: estimate?.estimatorComments ? <HtmltoPlainText htmlString={estimate?.estimatorComments} /> : "" },
          { name: "Approval Mode", value: estimate?.approvalMode },
          { name: "Approved Status", value: statusBodyTemplate(estimate?.approvedStatus) },
          { name: "Approval Comments", value: estimate?.approvalComments ? <HtmltoPlainText htmlString={estimate?.approvalComments} /> : "" },
          { name: "Updated By", value: (updatedByIdData != null && updatedByIdData != undefined) ? showUpdateUser(updatedByIdData) : "" },
          { name: "Approved On", value: estimate?.approvedon ? formatDateTimeFormServerView(estimate?.approvedon) : "" },
          { name: "Created At", value: estimate?.creationTime ? formatDateTimeFormServerView(estimate?.creationTime) : "" },
          { name: "Consent Signature", value: concernImage }
        ],
        estimatesAmountDetails: [
          { name: "Job Sheet Service Charge Amount", value: estimate?.serviceCharge >= 0 ? CurrencyFormatter({ value: estimate?.serviceCharge }) : "" },
          { name: "Total Price Of Parts", value: estimate?.totalPriceOfParts >= 0 ? CurrencyFormatter({ value: estimate?.totalPriceOfParts }) : "" },
          { name: "Total Sac Amount", value: estimate?.totalSacAmount >= 0 ? CurrencyFormatter({ value: estimate?.totalSacAmount }) : "" },
          { name: "Total Taxes", value: estimate?.totalTaxes >= 0 ? CurrencyFormatter({ value: estimate?.totalTaxes }) : "" },
          { name: "Grand Total Of Estimate", value: estimate?.totalEstimation >= 0 ? CurrencyFormatter({ value: estimate?.totalEstimation }) : "" },
        ]
      },
      parts: estimate?.estimatePartDto,
      taxes: estimate?.estimateTaxDto
    },

    // systemInspections: [
    //     { name: "Inspected By", value: "MEL Admin" },
    //     { name: "Comments", value: "pending" },
    //     { name: "Inspection Completed At", value: "30 January, 2024 13:08" }],

    pendingComments: pendingComments?.map((item: any) => ({
      pendingReason: item.pendingReasonId ? pendingReason?.find((value: any) => value.id == item.pendingReasonId)?.name : "",
      pendingInformation: item.pendingInformation,
      commentedAt: item.commentedAt ? formatDateTimeFormServerView(item.commentedAt) : ""
    }))
    ,

    invoice: invoiceDetails?.map((eachInvoice: any, index: number) => {
      let data: any = {
        general: [
          { name: "Number", value: eachInvoice?.number ? eachInvoice.number : "" },
          { name: "Job Sheet", value: eachInvoice?.jobSheetNumber ? eachInvoice.jobSheetNumber : "" },
          { name: "Invoice Type", value: eachInvoice?.invoiceTypeName ? eachInvoice?.invoiceTypeName : "" },
          { name: "Dealer", value: eachInvoice?.dealerName ? eachInvoice?.dealerName : "" },
          { name: "Customer", value: eachInvoice?.customerName ? eachInvoice?.customerName : "" },
          { name: "Comments", value: eachInvoice?.comments ? <HtmltoPlainText htmlString={eachInvoice?.comments} /> : "" },
          { name: "Total Price Of Parts", value: eachInvoice?.totalPriceOfParts >= 0 ? CurrencyFormatter({ value: eachInvoice?.totalPriceOfParts }) : "" },
          { name: "Service Charge", value: eachInvoice?.serviceCharge >= 0 ? CurrencyFormatter({ value: eachInvoice?.serviceCharge }) : "" },
          { name: "Discount", value: eachInvoice?.discount >= 0 ? CurrencyFormatter({ value: eachInvoice?.discount }) : "" },
          { name: "Total Taxes", value: eachInvoice?.totalTaxes >= 0 ? CurrencyFormatter({ value: eachInvoice?.totalTaxes }) : "" },
          { name: "Total", value: eachInvoice?.total >= 0 ? CurrencyFormatter({ value: eachInvoice?.total }) : "" },
          { name: "Total After Discount", value: eachInvoice?.totalAfterDiscount >= 0 ? CurrencyFormatter({ value: eachInvoice?.totalAfterDiscount }) : "" },
          { name: "Locked", value: statusBodyTemplate(eachInvoice?.locked ? true : false) },
          { name: "Paid State", value: statusBodyTemplate(eachInvoice?.paidState ? true : false) },
          // { name: "Invoice Date", value: eachInvoice?.creationTime ? formatDateTimeForView(eachInvoice?.creationTime) : "" },
          { name: "Invoice Date", value: eachInvoice?.lockedDate ? formatDateMEL(eachInvoice?.lockedDate) : "" },
          { name: "Credit Days", value: eachInvoice?.creditDays },
          { name: "Warranty Status", value: eachInvoice?.warrantyStatus }
        ],
        partSKUs: eachInvoice?.partSkus?.length > 0 ? eachInvoice?.partSkus?.map((eachPart: any) => {
          let data: any = {};
          // data['partSku'] = eachPart?.partSkuId ? eachPart?.partSkuId : "";
          data['partSku'] = eachPart?.number ? eachPart?.number : "";
          data['partNumber'] = eachPart?.partNumber ? eachPart?.partNumber : "";
          data['partDescription'] = eachPart?.partDescription ? eachPart?.partDescription : "";
          data['partHSNNumber'] = eachPart?.partHsnNumber ? eachPart?.partHsnNumber : "";
          data['foc'] = eachPart?.totalPrice ? false : true;
          data['price'] = eachPart?.price >= 0 ? CurrencyFormatter({ value: eachPart?.price }) : "";
          data['taxAmount'] = eachPart?.totalPartTaxAmount >= 0 ? CurrencyFormatter({ value: eachPart?.totalPartTaxAmount }) : "";
          data['totalPrice'] = eachPart?.totalPrice >= 0 ? CurrencyFormatter({ value: eachPart?.totalPrice }) : "";
          return data;
        }) : [],
        taxes: eachInvoice?.taxes?.length > 0 ? eachInvoice?.taxes?.map((eachTax: any) => {
          let data: any = {};
          data['name'] = eachTax?.name ? eachTax?.name : "";
          data['sacNumber'] = eachTax?.sacNo ? eachTax?.sacNo : "";
          data['quantity'] = eachTax.quantity ? eachTax.quantity : "";
          data['unitPrice'] = eachTax?.unitPrice >= 0 ? CurrencyFormatter({ value: eachTax?.unitPrice }) : "";
          data['amount'] = eachTax?.amount >= 0 ? CurrencyFormatter({ value: eachTax?.amount }) : "";
          data['taxPercentage'] = eachTax?.taxPrc ? eachTax?.taxPrc + " %" : "";
          data['applicableTax'] = eachTax?.totalTaxAmount >= 0 ? CurrencyFormatter({ value: eachTax?.totalTaxAmount }) : "";
          data['totalAmount'] = eachTax?.totalAmount >= 0 ? CurrencyFormatter({ value: eachTax?.totalAmount }) : "";
          return data;
        }) : [],
        receivedPayments: receivedPayment?.length > 0 ? receivedPayment[index] : []
      };
      return data;
    }),
    incomingCourier: [
      { name: "Number", value: jobsheet?.incomingDispatch?.number ? jobsheet?.incomingDispatch?.number : "" },
      { name: "Job Sheet", value: jobsheet?.incomingDispatch?.number ? (jobsheet?.number ? `${jobsheet?.number}` : "To be generated") : "" },
      { name: "Notes", value: jobsheet?.incomingDispatch?.notes ? <HtmltoPlainText htmlString={jobsheet?.incomingDispatch?.notes} /> : "" },
      { name: "Received Date", value: jobsheet?.incomingDispatch?.receivedDate ? formatDateTimeForView(jobsheet?.incomingDispatch?.receivedDate, false) : "" },
      // { name: "Received By", value: jobsheet?.incomingDispatch ? employeeDropList?.filter((eachEmployee: any) => eachEmployee.value == jobsheet?.incomingDispatch?.receivedId)?.[0]?.label : "" },
      { name: "Received By", value: jobsheet?.incomingDispatch?.receivedByName ? jobsheet?.incomingDispatch?.receivedByName : "" },
      { name: "Courier", value: jobsheet?.incomingDispatch?.courierMasterName == OTHER ? jobsheet?.incomingDispatch?.courierName : jobsheet?.incomingDispatch?.courierMasterName },
      { name: "Awb Number", value: jobsheet?.incomingDispatch?.awbNumber ? jobsheet?.incomingDispatch?.awbNumber : "" }],

    outgoingCourier: [
      { name: "Number", value: jobsheet?.outgoingDispatch?.number ? jobsheet?.outgoingDispatch?.number : "" },
      { name: "Job Sheet", value: jobsheet?.outgoingDispatch?.number ? (jobsheet?.number ? `${jobsheet?.number}` : "To be generated") : "" },
      { name: "Notes", value: jobsheet?.outgoingDispatch?.notes ? <HtmltoPlainText htmlString={jobsheet?.outgoingDispatch?.notes} /> : "" },
      { name: "Packed By", value: jobsheet?.outgoingDispatch?.packedBy ? jobsheet?.outgoingDispatch?.packedBy : "" },
      // { name: "Packed Date", value: jobsheet?.outgoingDispatch?.packedDate ? formatDateTimeForView(jobsheet?.outgoingDispatch?.packedDate, false) : "" },
      { name: "Packed Date", value: jobsheet?.outgoingDispatch?.packedDate ? formatDateTimeFormServerView(jobsheet?.outgoingDispatch?.packedDate, false) : "" },
      { name: "Delivery Date", value: jobsheet?.outgoingDispatch?.deliveryDate ? formatDateTimeForView(jobsheet?.outgoingDispatch?.deliveryDate) : "" },
      { name: "Delivery Type", value: jobsheet?.outgoingDispatch?.deliveryType ? jobsheet?.outgoingDispatch?.deliveryType : "" },
      { name: "Delivered To", value: jobsheet?.outgoingDispatch?.deliveredTo ? jobsheet?.outgoingDispatch?.deliveredTo : "" },
      // { name: "Delivered By", value: jobsheet?.outgoingDispatch?.deliveredById ? employeeDropList?.filter((eachEmployee: any) => eachEmployee.value == jobsheet?.outgoingDispatch?.deliveredById)?.[0]?.label : "" },
      { name: "Delivered By", value: jobsheet?.outgoingDispatch?.deliveredByName ? jobsheet?.outgoingDispatch?.deliveredByName : "" },
      { name: "Approximate Value", value: jobsheet?.outgoingDispatch?.approximateValue ? CurrencyFormatter({ value: jobsheet?.outgoingDispatch?.approximateValue }) : "" }],

    audits: jobsheet?.jobStatusTransitionDto?.length > 0 ? jobsheet?.jobStatusTransitionDto.map((eachAudit: any) => {
      let data: any = {};
      //   let user = employeeDropList?.filter((eachEmployee: any) => (eachEmployee?.value == eachAudit?.userId))?.[0]?.label;
      data['user'] = eachAudit?.name ? eachAudit?.name : "";
      data['event'] = eachAudit?.event ? eachAudit?.event : "";
      data['from'] = eachAudit?.from ? eachAudit?.from : "";
      data['to'] = eachAudit?.to ? eachAudit?.to : "";
      data['tat'] = convertMinutes(((eachAudit?.tat ? eachAudit?.tat : 0) + 1) / 60);
      data['creationTime'] = eachAudit?.creationTime ? formatDateTimeFormServerView(eachAudit?.creationTime) : "";
      return data;

    }) : [],
    enquiries: [
      // { jobSheet: "Job Sheet: BS2", dateOfEnquiry: "24 January, 2024 10:06", enquirerName: "Harish", isCustomer: true, attendedBy: "MEL Admin" }
    ],
    additionalExpenses: [
      { name: "Documents", value: documentTemplate(franchiseDocumet, "franchise") }
    ],
    approveDocuments: [
      { name: "Documents", value: documentTemplate(approveDocument, "approve") }
    ]
  };

  const printServiceRequest = () => {
    dispatch(downloadServiceRequestLoading(id));
  };

  const printExchangeInvoice = () => {
    dispatch(printExchangeInvoiceLoading(id));
  };

  const emailExchangeInvoice = () => {
    dispatch(emailExchangeInvoiceLoading(id));
  };

  const emailServiceHistory = () => {
    dispatch(emailServiceHistoryLoading(id));
  };

  const printServiceHistory = () => {
    dispatch(printServiceHistoryLoading(id));
  };

  const downlaodJobSheet = () => {
    dispatch(downloadJobSheetLoading(id));
  };

  const createFeedback = () => {
    navigate(`${ROUTER.FEEDBACK_CREATE}/${id}`);
  };

  const headerTemplate = (val: any) => {
    return (
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end" style={{ width: "100px" }}  >
          <div className="align-right">
            {val}
          </div>
        </div>
      </div>
    );
  };

  const htmlHandler = (htmlElement: string) => {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: htmlElement }} className='pending-information-large'/>
      </div>
    );
  };

  const redirectReceivedPayment = (data: any) => {
    return (
      <div className="d-flex justify-content-start">
        <div className="" style={{ width: "100px" }}  >
          <div className="align-right">
            {
              hasAdminOrEmployeeRoleHandler() ? <Link to={`${ROUTER.RECEIVED_PAYMENT_VIEW}/${data.receivedPaymentId}`}>{`#${data.receivedPaymentId}`}</Link> : <>{`#${data.receivedPaymentId}`}</>
            }

          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {visible && dialogContainer(error)}
      <div className="pagecontent" id='pagecontent'>
        <Container fluid>
          <BreadcrumbWithFilter
            title={(hasCustomerRoleHandler() || hasDealerRoleHandler() || hasDealerSubuserRoleHandler()) ? "Service Request" : "Job Sheets"}
            titlePath={ROUTER.JOB_SHEETS_GRID}
            breadcrumbItem={(hasCustomerRoleHandler() || hasDealerRoleHandler() || hasDealerSubuserRoleHandler()) ? "Service Request Details" : "Job Sheet Details"}
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            customClassName='fit-content'
            btnClassName="minimize-button"
            isShowFirstButton={isShowEdit(jobsheet?.status, jobsheet?.isFeedbackPending, invoiceDetails) ? true : false}
            firstButtonLabel='Edit Job Sheet'
            onFirstButtonClick={() => {
              if (isCreateFeedback(jobsheet?.status)) {
                createFeedback();
              } else {
                editJobSheet();
              }
            }
            }
            isShowThirdButton={isShowPrintJobSheet()}
            thirdButtonLabel='Print Job Sheet'
            onThirdButtonClick={() => downlaodJobSheet()}
            thirdButtonDisabled={false}

            isShowForthButton={isShowPrintServiceRequest(jobsheet?.status ? jobsheet?.status : "")}
            forthButtonLabel='Print Service Request'
            onForthButtonClick={() => printServiceRequest()}
            forthButtonDisabled={false}


            isShowFifthButton={isShowPrintEstimate(jobsheet?.status ? jobsheet?.status : "", jobsheet?.warrantyStatus ? jobsheet?.warrantyStatus : "") ? true : false}
            fifthButtonLabel='Print Estimate'
            fifthButtonDisabled={false}
            onFifthButtonClick={() => {
              dispatch(downloadEstimateJobSheetLoading(id));
            }}

            // isShowSithButton={true}
            // sixthButtonLabel='Create Exchange Parts Outbound'
            // sithButtonDisabled={true}

            isShowSeventhButton={isShowCreatePartsOutbound(jobsheet?.status ? jobsheet?.status : "", invoiceDetails, jobsheet, employeeDropList, technicianDropList) ? true : false}
            seventhButtonLabel='Create Part Outbound'
            seventhButtonDisabled={false}
            onSeventhButtonClick={() => {
              navigate(ROUTER.INVOICE_CREATE + '/' + id);
            }}

            isShowEighthButton={isShowCreateEnquiry() ? true : false}
            eighthButtonLabel='Create Enquiry'
            eighthButtonDisabled={false}
            onEighthButtonClick={() => {
              navigate(ROUTER.ENQUIRY_CREATE + "/" + id);
            }}

            isShowNinethButton={isShowEmailEstimate(jobsheet?.status ? jobsheet?.status : "", jobsheet?.warrantyStatus ? jobsheet?.warrantyStatus : "", jobsheet?.isExchange ? true : false) ? true : false}
            // isShowNinethButton={false}
            ninethButtonLabel='Email Estimate'
            ninethButtonDisabled={false}
            onNinethButtonClick={() => {
              dispatch(sendMailJobSheetLoading(id));
            }}

            isShowTenthButton={isShowEmailEstimate(jobsheet?.status ? jobsheet?.status : "", jobsheet?.warrantyStatus ? jobsheet?.warrantyStatus : "", jobsheet?.isExchange ? true : false) ? true : false}
            // isShowTenthButton={false}
            tenthButtonLabel='SMS Estimate'
            tenthButtonDisabled={false}
            onTenthButtonClick={() => {
              dispatch(sendSMSJobSheetLoading(id));
            }}

            isShowEleventhButton={isShowEditPartsOutbound(jobsheet?.status ? jobsheet?.status : "", invoiceDetails) ? true : false}
            eleventhButtonLabel='Edit Part Outbound'
            eleventhButtonDisabled={false}
            onEleventhButtonClick={() => {
              navigate(ROUTER.INVOICE_EDIT + '/' + serviceInvoiceId + "/" + id);
              // navigate(ROUTER.INVOICE_EDIT + '/' + serviceInvoiceId)
            }}

            isShowTwelvethButton={isShowPrintInvoice(jobsheet?.status ? jobsheet?.status : "", invoiceDetails) ? true : false}
            twelvethButtonLabel='Print Invoice'
            twelvethButtonDisabled={false}
            onTwelvethButtonClick={() => {
              dispatch(downloadInvoiceJobSheetLoading(id));
            }}

            isShowThirteenthButton={isShowEmailInvoice(jobsheet?.status ? jobsheet?.status : "", jobsheet?.isExchange ? true : false, invoiceDetails) ? true : false}
            thirteenthButtonLabel='Email Invoice'
            thirteenthButtonDisabled={false}
            onThirteenthButtonClick={() => {
              dispatch(sendInvoiceMailJobSheetLoading(id));
            }}

            isShowFourteenthButton={isShowCreateExchangePartOutbound(jobsheet?.status ? jobsheet?.status : "", jobsheet?.isExchange ? true : false, invoiceDetails, jobsheet) ? true : false}
            fourteenthButtonLabel='Create Exchange Part Outbound'
            fourteenthButtonDisabled={false}
            onFourteenthButtonClick={() => {
              navigate(ROUTER.INVOICE_CREATE + '/' + id);
            }}

            isShowFifteenthButton={isShowEditExchangePartOutbound(jobsheet?.status ? jobsheet?.status : "", jobsheet?.isExchange ? true : false, invoiceDetails) ? true : false}
            fifteenthButtonLabel='Edit Exchange Part Outbound'
            fifteenthButtonDisabled={false}
            onFifteenthButtonClick={() => {
              // navigate(ROUTER.INVOICE_EDIT + '/' + exchangeServiceInvoiceId)
              navigate(ROUTER.INVOICE_EDIT + '/' + exchangeServiceInvoiceId + "/" + id);
            }}


            isShowSixteenthButton={isShowPrintExchnageInvoice(jobsheet?.status ? jobsheet?.status : "", jobsheet?.isExchange ? true : false, invoiceDetails) ? true : false}
            sixteenthButtonLabel='Print Exchange Invoice'
            sixteenthButtonDisabled={false}
            onSixteenthButtonClick={() => printExchangeInvoice()}

            isShowSeventeenthButton={isShowPrintExchnageInvoice(jobsheet?.status ? jobsheet?.status : "", jobsheet?.isExchange ? true : false, invoiceDetails) ? true : false}
            seventeenthButtonLabel='Email Exchange Invoice'
            seventeenthButtonDisabled={false}
            onSeventeenthButtonClick={() => emailExchangeInvoice()}

            isShowSecondButton={(hasAdminOrEmployeeRoleHandler()) ? true : false}
            secondButtonLabel='Email MOS'
            onSecondButtonClick={()=>emailServiceHistory()}

            isShowEighteenthButton={(hasManufacturerRoleHandler()) ? true : false}
            eighteenthButtonLabel='Print MOS'
            onEighteenthButtonClick={()=>printServiceHistory()}

          />
          <Row>
            <div className="view">
              <div className="general-details service-details">
                <span>{hasCustomerRoleHandler() ? "Service Request Details" : `Job Sheet Details `} -</span>{jobsheet?.status && <span className='color-blue'>{`${jobsheet?.status ? jobsheet?.status : ""}`}</span>}
              </div>
              <div className="subview">
                <div className="parentCls">
                  {
                    JobSheetView.josheetDetails.map((data, index) => {
                      return (
                        <div key={index} className={` pt-1 childCls`}>
                          <div>{data.name}</div>
                          <div>{data.value ? data.value : <div className='none-text'>-</div>}</div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
          </Row>
          <Row className='mt-2'>
            <div className="view">
              <div className="general-details">
                <b>Estimate</b>
              </div>
              <div className="subview">
                {estimate ?
                  <Row>
                    {/* <div className="parentCls"> */}
                    <Col lg={6} style={{ borderRight: "1px solid #eee" }}>
                      {
                        JobSheetView?.estimate.general?.generalDetais?.map((data: any, index: number) => {
                          return (
                            <div key={index} className={`pt-1 childCls`}>
                              <div>{data?.name}</div>
                              <div>{data?.name == "Consent Signature" ? (data?.value ? <img src={data?.value} alt="" style={{ width: "150px ", maxHeight: "200px" }} /> : (<div className='none-text'>-</div>)) : (data.value ? data.value : <div className='none-text'>-</div>)}</div>
                            </div>
                          );
                        })
                      }
                    </Col>
                    <Col lg={6}>
                      {
                        JobSheetView?.estimate.general?.estimatesAmountDetails?.map((data: any, index: number) => {
                          return (
                            <div key={index} className={`pt-1 estimate_amount`}>
                              <div>{data?.name}</div>
                              <div>{(data.value ? data.value : <div className='none-text'>-</div>)}</div>
                            </div>
                          );
                        })
                      }</Col>
                    {/* </div> */}
                  </Row>

                  : <div className='text-center'>No Estimate found</div>
                }

                <div>
                  <div className="view mt-3">
                    <div className="general-details">
                      <b>Parts</b>
                    </div>
                    <div className="subview">
                      {
                        JobSheetView?.estimate?.parts?.length > 0 ?
                          <DataTable size='large' value={JobSheetView?.estimate?.parts} showGridlines tableStyle={{ minWidth: '50rem', padding: "30px" }}>
                            <Column field="partId" header="Part" body={(data, props) => data?.partId ? partsDropList.filter((eachParts: any) => eachParts?.value == data?.partId)?.[0]?.label : ""}></Column>
                            <Column field="partDescription" header="Part Description"></Column>
                            <Column field="quantityRequired" header="Quantity Reqd"></Column>
                            <Column header="Unit Price" body={(data, props) => data?.unitPrice >= 0 ? headerTemplate(CurrencyFormatter({ value: data?.unitPrice })) : ""}></Column>
                            <Column header="Part Price" body={(data, props) => data?.partPrice >= 0 ? headerTemplate(CurrencyFormatter({ value: data?.partPrice })) : ""}></Column>
                            <Column header="Tax Percentage" body={(data, props) => data?.taxPercentage ? headerTemplate(`${data.taxPercentage} %`) : 0}></Column>
                            <Column header="Part Tax Amount" body={(data, props) => data?.totalPriceTax >= 0 ? headerTemplate(CurrencyFormatter({ value: data?.totalPriceTax })) : ""}></Column>
                            <Column header="Total Price" body={(data, props) => data?.totalPrice ? headerTemplate(CurrencyFormatter({ value: data?.totalPrice })) : ""}></Column>
                          </DataTable>
                          :
                          <Col lg={12} className='text-center'>
                            <div>No Parts found</div>
                          </Col>}
                    </div>
                  </div>

                  <div className="view mt-2">
                    <div className="general-details">
                      <b>Taxes (SAC)</b>
                    </div>
                    <div className="subview">
                      {
                        JobSheetView?.estimate?.taxes?.length > 0 ?
                          <DataTable size='large' value={JobSheetView.estimate.taxes} showGridlines tableStyle={{ minWidth: '50rem', padding: "30px" }}>
                            <Column field="name" header="Name"></Column>
                            <Column field="sacNumber" header="Sac Number"></Column>
                            <Column header="Amount" body={(data, props) => headerTemplate(CurrencyFormatter({ value: data.amount }))}></Column>
                            <Column header="Tax Percentage" body={(data, props) => data?.taxPercentage ? headerTemplate(`${data.taxPercentage} %`) : ""}></Column>
                            <Column header="Tax Amount" body={(data, props) => headerTemplate(CurrencyFormatter({ value: data.totalTax }))}></Column>
                            <Column header="Total Amount" body={(data, props) => headerTemplate(CurrencyFormatter({ value: data.amountWithTax }))}></Column>
                          </DataTable> :
                          <Col lg={12} className='text-center'>
                            <div>No Taxes found</div>
                          </Col>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          {/* {false && <Row className='mt-2'>
                        <div className="view">
                            <div className="general-details">
                                <b>System Inspections</b>
                            </div>
                            <div className="subview">
                                <Row>
                                    {
                                        JobSheetView?.systemInspections?.length > 0 ?
                                            JobSheetView?.systemInspections?.map((data: any, index: number) => {
                                                return (
                                                    <Col lg={3} key={index} className={`${index >= 4 ? "mt-4" : "mt-1"}`}>
                                                        <div className='mb-2'>{data.name}</div>
                                                        <b>{data.value ? data.value : <div className='none-text'>-</div>}</b>
                                                    </Col>
                                                )
                                            }) :
                                            <Col lg={12} className='text-center'>
                                                <div>No System Inspection found</div>
                                            </Col>
                                    }
                                </Row>
                            </div>
                        </div>
                    </Row>} */}


          {/* Job Sheet Pending Comments */}

          {
            (!hasCustomerRoleHandler() && !hasDealerRoleHandler() && !hasFranchiseRoleHandler() && !hasFranchiseSubuserRoleHandler()) && (
              <Row className='mt-2'>
                <div className="view">
                  <div className="general-details">
                    <b>Job Sheet Present Status</b>
                  </div>
                  <div className="subview">
                    <Row>
                      {
                        JobSheetView.pendingComments && JobSheetView.pendingComments?.length > 0 ?
                        <div className="card ps-0 pe-0" id="table-border">
                          <DataTable size='large' value={JobSheetView.pendingComments} showGridlines tableStyle={{ minWidth: '50rem', padding: "30px" }}>
                            <Column field="pendingReason" header="Pending Reason"></Column>
                            <Column field="pendingInformation" header="Pending Information" body={(rowData: any) => htmlHandler(rowData.pendingInformation)}></Column>
                            <Column field="commentedAt" header="Commented At"></Column>
                          </DataTable>
                          </div>
                           :
                          <Col lg={12} className='text-center'>
                            <div>No Job Sheet Present Status found</div>
                          </Col>
                      }
                    </Row>
                  </div>
                </div>
              </Row>
            )
          }



          {(!hasFranchiseRoleHandler() && !hasFranchiseSubuserRoleHandler() && !hasManufacturerRoleHandler()) && JobSheetView.invoice?.map((eachInvoice: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <Row className='mt-2'>
                  <div className="view">
                    <div className="general-details">
                      <b>{EXCHANGE_INVOICE == eachInvoice["Invoice Type"] ? "Exchange Invoice" : "Invoice"}</b>
                    </div>
                    <div className="subview">
                      <div className="parentCls">
                        {
                          eachInvoice?.general?.length > 0 ?
                            eachInvoice?.general?.map((data: any, index: number) => {
                              return (
                                <div key={index} className={` pt-1 childCls`}>
                                  <div>{data.name}</div>
                                  <div>{data.value ? data.value : <div className='none-text'>-</div>}</div>
                                </div>
                              );
                            }) :
                            <Col lg={12} className='text-center'>
                              <div>No Invoice found</div>
                            </Col>
                        }

                      </div>
                      <div className="view mt-2">
                        <div className="general-details">
                          <b>Part SKUs</b>
                        </div>
                        <div className="subview">
                          {
                            eachInvoice?.partSKUs?.length > 0 ?
                              <DataTable size='large' value={eachInvoice?.partSKUs} showGridlines tableStyle={{ minWidth: '50rem', padding: "30px" }}>
                                <Column field="partSku" header="Part Sku"></Column>
                                <Column field="partNumber" header="Part Number"></Column>
                                <Column field="partDescription" header="Part Description"></Column>
                                <Column field="partHSNNumber" header="Part HSN Number"></Column>
                                <Column header="FOC" body={(data, props) => statusBodyTemplate(data.foc)}></Column>
                                <Column header="Price" field="price" body={(data, props) => data?.price ? headerTemplate(data.price) : ""} ></Column>
                                <Column header="Tax Amount" field="taxAmount" body={(data, props) => data?.taxAmount ? headerTemplate(data.taxAmount) : ""} ></Column>
                                <Column header="Total Price" field="totalPrice" body={(data, props) => data?.totalPrice ? headerTemplate(data.totalPrice) : ""} ></Column>
                              </DataTable> :
                              <Col lg={12} className='text-center'>
                                <div>No Part SKUs found</div>
                              </Col>}
                        </div>
                      </div>
                      <div className="view mt-2">
                        <div className="general-details">
                          <b>Taxes</b>
                        </div>
                        <div className="subview">
                          {
                            eachInvoice?.taxes?.length > 0 ?
                              <DataTable size='large' value={eachInvoice?.taxes} showGridlines tableStyle={{ minWidth: '50rem', padding: "30px" }}>
                                <Column field="name" header="Name"></Column>
                                <Column field="sacNumber" header="SAC Number"></Column>
                                <Column header="Quantity" field='quantity' body={(data, props) => data?.quantity ? headerTemplate(data.quantity) : ""}></Column>
                                <Column header="Unit Price" field='unitPrice' body={(data, props) => data?.unitPrice ? headerTemplate(data.unitPrice) : ""}></Column>
                                <Column header="Amount" field='amount' body={(data, props) => data?.amount ? headerTemplate(data.amount) : ""}></Column>
                                <Column field="taxPercentage" header="Tax Percentage" body={(data, props) => data?.taxPercentage ? headerTemplate(data.taxPercentage) : ""}></Column>
                                <Column header="Applicable Tax" field="applicableTax" body={(data, props) => data?.applicableTax ? headerTemplate(data.applicableTax) : ""}></Column>
                                <Column header="Total Amount" field="totalAmount" body={(data, props) => data?.totalAmount ? headerTemplate(data.totalAmount) : ""} ></Column>
                              </DataTable> :
                              <Col lg={12} className='text-center'>
                                <div>No Taxes found</div>
                              </Col>}
                        </div>
                      </div>
                      {
                        (!hasFranchiseSubuserRoleHandler() && !hasFranchiseRoleHandler() && !hasManufacturerRoleHandler()) && <div className="view mt-2">
                          <div className="general-details">
                            <b>Received Payments</b>
                          </div>
                          <div className="subview">
                            {
                              (eachInvoice?.receivedPayments?.length > 0) ? <>
                                <DataTable size='large' value={eachInvoice?.receivedPayments} showGridlines tableStyle={{ minWidth: '50rem', padding: "30px" }}>
                                  <Column field="receivedPaymentId" header="Received Payment" body={(data) => redirectReceivedPayment(data)}></Column>
                                  <Column field="amount" header="Amount" body={(data, props) => headerTemplate(CurrencyFormatter({ value: data.amount }))}></Column>
                                  <Column header="Tds Deduction" field='tdsDeduction' body={(data, props) => headerTemplate(CurrencyFormatter({ value: data.tdsDeduction }))}></Column>
                                  <Column field="total" header="Total" body={(data, props) => headerTemplate(CurrencyFormatter({ value: data.total }))}></Column>
                                </DataTable>
                              </>
                                :
                                <Col lg={12} className='text-center'>
                                  <div>No Received Payments found</div>
                                </Col>}
                          </div>
                        </div>
                      }


                    </div>
                  </div>
                </Row>
              </React.Fragment>);
          })}


          {/* Incoming Courier */}

          {
            (!hasCustomerRoleHandler() && !hasDealerRoleHandler() && !hasFranchiseRoleHandler() && !hasFranchiseSubuserRoleHandler()) && (
              <Row className='mt-2'>
                <div className="view">
                  <div className="general-details">
                    <b>Incoming Courier</b>
                  </div>
                  <div className="subview">
                    {jobsheet?.incomingDispatch ?
                      <div className="parentCls">
                        {

                          JobSheetView.incomingCourier.map((data: any, index: number) => {
                            return (
                              <div key={index} className={` pt-1 childCls`}>
                                <div>{data.name}</div>
                                <div>{data.value ? data.value : <div className='none-text'>-</div>}</div>
                              </div>
                            );
                          })

                        }
                      </div> :

                      <div className='text-center'>No Incoming Courier found</div>
                    }
                  </div>
                </div>
              </Row>
            )
          }


          {/* Outgoing Courier */}

          {
            (!hasCustomerRoleHandler() && !hasDealerRoleHandler() && !hasFranchiseRoleHandler() && !hasFranchiseSubuserRoleHandler()) && (
              <Row className='mt-2'>
                <div className="view">
                  <div className="general-details">
                    <b>Outgoing Courier</b>
                  </div>
                  <div className="subview">
                    {jobsheet?.outgoingDispatch ?
                      <div className="parentCls">
                        {

                          JobSheetView.outgoingCourier.map((data: any, index: number) => {
                            return (
                              <div key={index} className={` pt-1 childCls`}>
                                <div>{data.name}</div>
                                <div>{data.value ? data.value : <div className='none-text'>-</div>}</div>
                              </div>
                            );
                          })
                        }
                      </div> :
                      <div className='text-center'>No Outgoing Courier found</div>
                    }
                  </div>
                </div>
              </Row>
            )
          }


          {/* Job Sheet Audits */}

          {
            (!hasCustomerRoleHandler() && !hasDealerRoleHandler() && !hasFranchiseRoleHandler() && !hasFranchiseSubuserRoleHandler()) && (
              <Row className='mt-2'>
                <div className="view">
                  <div className="general-details">
                    <b>Job Sheet Audits</b>
                  </div>
                  <div className="subview">
                    <Row>
                      {
                        JobSheetView.audits?.length > 0 ?
                          <DataTable size='large' value={JobSheetView.audits} showGridlines tableStyle={{ minWidth: '50rem', padding: "30px" }}>
                            <Column field="user" header="User"></Column>
                            <Column field="event" header="Event"></Column>
                            <Column field="from" header="From"></Column>
                            <Column field="to" header="To"></Column>
                            <Column field="creationTime" header="Created At" ></Column>
                            <Column field="tat" header="Tat" ></Column>
                          </DataTable> :
                          <Col lg={12} className='text-center'>
                            <div>No Audits found</div>
                          </Col>
                      }
                    </Row>
                  </div>
                </div>
              </Row>
            )
          }


          {/* Enquiries */}

          {
            (!hasCustomerRoleHandler() && !hasDealerRoleHandler() && !hasFranchiseRoleHandler() && !hasFranchiseSubuserRoleHandler()) && (
              <Row className='mt-2 mb-2'>
                <div className="view">
                  <div className="general-details">
                    <b>Enquiries</b>
                  </div>
                  <div className="subview">
                    <Row>
                      {modifiedValues?.length > 0 ?
                        <DataTableWrapper
                          ref={dt}
                          columnDetails={columns}
                          value={modifiedValues}
                          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
                          filterIcon={FilterIcon}
                          lazy
                          paginator={true}
                          stripedRows={true}
                          rowHover={true}
                          // filterDisplay="row"
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          totalRecords={totalRecords}
                          loading={loading}
                          emptyMessage="No Enquiry found."
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Displaying enquiries  {last} records of {totalRecords} in total"

                          first={lazyState.first}
                          onSort={onSort}
                          sortField={lazyState.sortField}
                          sortOrder={lazyState.sortOrder}
                          onFilter={onFilter}
                          filters={lazyState.filters}
                          onPage={onPage}
                          rows={lazyState.rows}
                        >
                        </DataTableWrapper>
                        : <div className='text-center'>No  enquiry found</div>
                      }
                    </Row>
                  </div>
                </div>
              </Row>
            )
          }


          {/* Franchise Documents */}

          {
            (!hasCustomerRoleHandler() && !hasDealerRoleHandler() && !hasFranchiseRoleHandler() && !hasFranchiseSubuserRoleHandler() && !hasManufacturerRoleHandler()) && (
              <Row className='mt-2 mb-2'>
                <div className="view">
                  <div className="general-details">
                    <b>Franchise Documents</b>
                  </div>
                  <div className="subview">
                    {franchiseDocumet.length > 0 ?
                      <div className="">
                        {/* {
                          JobSheetView.additionalExpenses.map((data: any, index: number) => {
                            return (
                              <div key={index} className={` pt-1 childCls`}>
                                <div>{data.name}</div>
                                <div>{data.value ? data.value : <div className='none-text'>-</div>}</div>
                              </div>
                            )
                          })
                        } */}
                        {documentTemplate(franchiseDocumet, "Franchise")}
                      </div> :
                      <div className='text-center'>Franchise document not found</div>
                    }
                  </div>
                </div>
              </Row>
            )
          }


          {/* Approve Documents */}

          {
            (!hasCustomerRoleHandler() && !hasDealerRoleHandler() && !hasFranchiseRoleHandler() && !hasFranchiseSubuserRoleHandler() && !hasManufacturerRoleHandler()) && (
              <Row className='mt-2 mb-2'>
                <div className="view">
                  <div className="general-details">
                    <b>Approve Documents</b>
                  </div>
                  <div className="subview">
                    {approveDocument.length > 0 ?
                      <div className="">
                        {/* {
                          JobSheetView.approveDocuments.map((data: any, index: number) => {
                            return (
                              <div key={index} className={` pt-1 childCls`}>
                                <div>{data.name}</div>
                                <div>{data.value ? data.value : <div className='none-text'>-</div>}</div>
                              </div>
                            )
                          })
                        } */}
                        {documentTemplate(approveDocument, "approvementDocument")}
                      </div> :
                      <div className='text-center'>Approved document not found</div>
                    }
                  </div>
                </div>
              </Row>
            )
          }


        </Container>
      </div>
    </React.Fragment >
  );
};

export default ViewJobSheet;















// let years = Math.floor(minutes / (60 * 24 * 365));
// minutes = minutes % (60 * 24 * 365);
// let months = Math.floor(minutes / (60 * 24 * 30));
// minutes = minutes % (60 * 24 * 30);
// let days = Math.floor(minutes / (60 * 24));
// minutes = minutes % (60 * 24);
// let hours = Math.floor(minutes / 60);
// let remainingMinutes = minutes % 60;

// let output = "";

// if (years > 0) {
//   output = `${years} years`;
// } else if (months > 0) {
//   output = `${months} months`;
// } else if (days > 0) {
//   output = `${days} days`;
// } else if (hours > 0) {
//   output = `${hours} hours`;
// } else if (remainingMinutes > 0) {
//   output = `${remainingMinutes} minutes`;
// } else {
//   output = "less than a minute";
// }
// return (output);